import { defineStore } from 'pinia';

export const useRandomStore = defineStore('random', {
  state: () => ({
    timestamp: null, // Almacena la fecha y hora
  }),
  actions: {
    setTimestamp() {
      // Obtener la fecha y hora actual
      const now = new Date();
      this.timestamp = now.toLocaleString(); // Formatear la fecha y hora
    },
  },
});