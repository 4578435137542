<template>
  <div class="marker-form" v-if="true">
    <div class="button">
      <button type="button" @click="getData">GET DATA</button>
      <button type="button" @click="postData">POST DATA</button>
    </div>
  </div>
  <div class="app" v-if="showRecorder">
    <button @click="toggleRecording" :disabled="isRecording">
      {{ isRecording ? "Recording..." : "Begin recording" }}
    </button>
    <button @click="stopRecording" :disabled="!isRecording">
      Stop recording
    </button>
    <button @click="sendAudio" :disabled="!audioBlob">Done!</button>
    <div class="audio-control">
      <audio v-if="audioUrl" :src="audioUrl" controls></audio>
    </div>
    <!-- </div> -->
  </div>
</template>

<script>
export default {
  name: "TestRemoteBackend",
  components: {},

  data() {
    return {
      isRecording: false,
      mediaRecorder: null,
      audioChunks: [],
      audioBlob: null,
      audioUrl: "",
      showRecorder: true,
    };
  },
  // created()
  // {
  // },
  methods: {
    async sendAudio() {
      if (!this.audioBlob) return;

      const formData = new FormData();
      formData.append("audio", this.audioBlob, "recording.wav");

      const backendUrl = "http://146.190.158.70:3000";
      try {
        const response = await fetch(backendUrl, {
          method: "POST",
          body: formData,
        });

        if (!response.ok) {
          throw new Error(`Error HTTP: ${response.status}`);
        }

        // Decodificar la respuesta JSON
        const data = await response.json();

        console.log("Respuesta completa del backend:", data); // Ver todo el objeto
        console.log("Resultado del script Python:", data.result); // Var1 y Var2

        // Mostrar el resultado en el cliente (opcional)
        alert(`Audio procesado. Resultado: ${JSON.stringify(data.result)}`);
      } catch (error) {
        console.error("Error al enviar el audio:", error);
        alert(
          "Error al procesar el audio. Revisa la consola para más detalles."
        );
      }
    },
    
    async getData() {
      const backendUrl = "http://146.190.158.70:3000";

      // Solicitud GET
      try {
        const response = await fetch(backendUrl);
        if (!response.ok) {
          throw new Error(
            `Error en la solicitud: ${response.status} ${response.statusText}`
          );
        }
        const data = await response.json(); // Obtener los datos como JSON
        console.log("Registros obtenidos:", data); // Mostrar los registros en la consola
      } catch (error) {
        console.error("Error en GET:", error.message); // Mostrar el error en la consola
      }
    },

    async postData() {
      const backendUrl = "http://146.190.158.70:3000";

      // Datos para la solicitud POST
      const postData = {
        title: "test POST",
        timestamp: new Date().toISOString(), // Fecha y hora en formato ISO
      };

      // Solicitud POST
      try {
        const response = await fetch(backendUrl, {
          method: "POST",
          headers: {
            "Content-Type": "application/json", // Indica que el cuerpo es JSON
          },
          body: JSON.stringify(postData), // Convierte el objeto a JSON
        });

        if (!response.ok) {
          throw new Error(
            `Error en la solicitud: ${response.status} ${response.statusText}`
          );
        }

        const data = await response.json();
        console.log(`Respuesta POST: ${JSON.stringify(data, null, 2)}`);
      } catch (error) {
        alert(`Error en POST: ${error.message}`);
      }
    },
    async toggleRecording() {
      if (this.isRecording) return;

      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          audio: true,
        });
        this.mediaRecorder = new MediaRecorder(stream);
        this.mediaRecorder.ondataavailable = (event) => {
          this.audioChunks.push(event.data);
        };
        this.mediaRecorder.onstop = () => {
          this.audioBlob = new Blob(this.audioChunks, { type: "audio/wav" });
          this.audioUrl = URL.createObjectURL(this.audioBlob);
          this.audioChunks = [];
        };
        this.mediaRecorder.start();
        this.isRecording = true;
      } catch (error) {
        console.error("Error al acceder al micrófono:", error);
      }
    },
    stopRecording() {
      if (this.mediaRecorder && this.isRecording) {
        this.mediaRecorder.stop();
        this.isRecording = false;
      }
    },
  },
};
</script>

<style scoped></style>
