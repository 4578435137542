<template>
  <div class="container">
    <div class="text-content" v-html="currentHtmlContent"></div>
    <br />

    <div class="audio-control">
      <audio :src="currentPage.audio" ref="audioPlayer" controls></audio>
    </div>

    <div class="grid-container" v-if="isExerciseA">
      <button
        v-for="(button, index) in buttonsA"
        :key="index"
        :class="{ active: selectedButton === index }"
        @click="handleButtonA(index)"
      >
        {{ button }}
      </button>
    </div>

    <div class="grid-container" v-if="isExerciseB">
      <button
        v-for="(button, index) in buttonsB"
        :key="index"
        :class="{ active: selectedButton === index }"
        @click="handleButtonB(index)"
      >
        {{ button }}
      </button>
    </div>

    <div class="buttons">
      <button @click="goToPreviousPage" :disabled="currentIndex === 0">
        Previous
      </button>
      <button @click="goToNextPage" :disabled="currentIndex === pages.length">
        Next
      </button>
    </div>
  </div>
</template>

<script>
import pagesData from "@/assets/test.json";

export default {
  name: "MainPage",
  props: {},
  data() {
    return {
      pages: [],
      currentHtmlContent: "",
      currentIndex: 0,
      isRecording: false,
      mediaRecorder: null,
      audioChunks: [],
      audioBlob: null,
      audioUrl: "",
      showRecorder: true,
      buttonsA: ["2", "3", "5", "6"],
      buttonsB: ["", ""],
      selectedButton: null,
      a: null,
      b: null,
      indexArray: [],
      subjectName: "Juan XXIII",
      indexes: null,
    };
  },
  computed: {
    currentPage() {
      return this.pages[this.currentIndex];
    },
    isExerciseA() {
      return this.currentPage.type === "exercise";
    },
    isExerciseB() {
      this.getA();
      return this.currentPage.type === "exercise2";
    },
  },
  created() {
    this.pages = pagesData;
    this.loadHtmlContent();
  },

  methods: {
    handleButtonA(index) {
      this.selectedButton = index;
      this.logButtonIndexA(index);
    },
    logButtonIndexA(index) {
      this.indexes = this.buttonsA[index];
      console.log("Botón clickeado, índice:", this.indexes);
    },
    handleButtonB(index) {
      this.selectedButton = index;
      this.logButtonIndexB(index);
    },
    logButtonIndexB(index) {
      this.indexes = this.buttonsB[index];
      console.log("Botón clickeado, índice:", this.indexes);
    },
    getA() {
      this.buttonsB = [this.currentPage.a, this.currentPage.b];
    },
    addToIndexArray() {
      // Evita duplicados si es necesario
      if (!this.indexArray.includes(this.indexes)) {
        this.indexArray.push(this.indexes);
      }
      console.log("Índices seleccionados:", this.indexArray);
    },
    async postData() {
      const backendUrl = "http://146.190.158.70:3000";
      // let data = {
      // id: experimental_trials.select("ID"),
      // rt: experimental_trials.select("rt"),
      // stim: experimental_trials.select("stim"),
      // response: experimental_trials.select("response"),
      // type: experimental_trials.select("condition"),
      // expected_answer: experimental_trials.select("expected_answer"),
      // environment: experimental_trials.select("environment"),
      // list: experimental_trials.select("type"),
      //};
      // Datos para la solicitud POST
      const data2post = {
        title: this.subjectName,
        responses: this.indexArray,
        timestamp: new Date().toISOString(), // Fecha y hora en formato ISO
      };
      // Solicitud POST
      try {
        const response = await fetch(backendUrl, {
          method: "POST",
          headers: {
            "Content-Type": "application/json", // Indica que el cuerpo es JSON
          },
          body: JSON.stringify(data2post), // Convierte el objeto a JSON
        });

        if (!response.ok) {
          throw new Error(
            `Error en la solicitud: ${response.status} ${response.statusText}`
          );
        }

        const data = await response.json();
        console.log(`Respuesta POST: ${JSON.stringify(data, null, 2)}`);
      } catch (error) {
        alert(`Error en POST: ${error.message}`);
      }
    },
    async getData() {
      const backendUrl = "http://146.190.158.70:3000";

      // Solicitud GET
      try {
        const response = await fetch(backendUrl);
        if (!response.ok) {
          throw new Error(
            `Error en la solicitud: ${response.status} ${response.statusText}`
          );
        }
        const data = await response.json(); // Obtener los datos como JSON
        console.log("Registros obtenidos:", data); // Mostrar los registros en la consola
      } catch (error) {
        console.error("Error en GET:", error.message); // Mostrar el error en la consola
      }
    },

    async loadHtmlContent() {
      try {
        const response = await fetch(this.currentPage.htmlPath);
        if (!response.ok) throw new Error("Error al cargar el archivo HTML");
        this.currentHtmlContent = await response.text();
      } catch (error) {
        console.error("Error al cargar contenido HTML:", error);
        this.currentHtmlContent = "<p>Error al cargar el contenido.</p>";
      }
    },
    goToPreviousPage() {
      if (this.currentIndex > 0) {
        this.currentIndex--;
        this.loadHtmlContent();
        this.selectedButton = null;
      }
      if (this.currentIndex == 0) {
        this.showRecorder = false;
      } else {
        this.showRecorder = true;
      }
    },
    goToNextPage() {
      if (this.isExerciseA) {
        this.addToIndexArray();
      }
      if (this.currentIndex < this.pages.length - 1) {
        this.currentIndex++;
        this.loadHtmlContent();
        this.selectedButton = null;
        this.postData();
      } 
      if (this.currentIndex == 0) {
        this.showRecorder = false;
      } else {
        this.showRecorder = true;
      }
    },
  },
};
</script>

<style>
body {
  display: flex;
  justify-content: center;
  /* Centrado horizontal */
  align-items: center;
  /* Centrado vertical */
  min-height: 100vh;
  /* Ocupa todo el alto de la ventana */
  margin: 0;
  /* Elimina márgenes por defecto */
  background-color: #f4f4f4;
  /* Fondo opcional */
}

.container {
  max-width: 700px;
  text-align: center;
  /* Centrado del texto */
  padding: 20px;
  /* Espaciado opcional */
  background-color: #fff;
  /* Fondo del contenedor */
  border-radius: 10px;
  /* Esquinas redondeadas */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  /* Sombra opcional */
}

.text-content {
  text-align: left;
  margin-bottom: 20px;
}

.text-content h1 {
  text-align: center;
  font-size: 28px;
  margin-bottom: 10px;
}

.text-content h2 {
  font-size: 24px;
  margin-bottom: 10px;
  color: gray;
}

.text-content h3 {
  font-size: 22px;
  margin-bottom: 10px;
  color: gray;
}

.text-content h4 {
  text-align: center;
  font-size: 22px;
  margin-bottom: 10px;
  color: black;
}

.text-content p {
  font-size: 18px;
  line-height: 1.5;
  margin-bottom: 10px;
}

.text-content ol {
  margin-left: 20px;
}

.text-content li {
  font-size: 20px;
}

.buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 100px;
}

button {
  font-size: 1em;
  padding: 10px 20px;
  cursor: pointer;
}

button:disabled {
  background-color: lightgray;
  cursor: not-allowed;
}

.audio-control {
  margin-top: 20px;
  margin-bottom: 20px;
  text-align: center;
}

main div {
  float: left;
  clear: none;
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
  width: 200px;
  margin: auto;
  padding: 20px;
}

button {
  padding: 15px;
  font-size: 16px;
  border: none;
  background-color: #3498db;
  color: white;
  border-radius: 5px;
  cursor: pointer;
  transition: background 0.3s;
}

button:hover {
  background-color: #2980b9;
}

.active {
  background-color: #e74c3c;
}
</style>
