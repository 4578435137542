<template>
  <!-- <div class="header">
    <h1>Experimentos online</h1>
  </div> -->
  <div class="container">
    <div class="button-grid">
      <button @click="navigateTo('EnglishAccentCourse')">English Course</button>
      <button>Self Paced Battery</button>
      <button>Digit Span Alone</button>
      <button @click="navigateTo('TestPage')">Backend</button>
      <button @click="navigateTo('TestBackend')">Test</button>
        <!-- <button @click="generateRandom">Generar Número Aleatorio</button> -->
    </div>
  </div>
    <!-- <button @click="generateRandom">Generar Número Aleatorio</button> -->
</template>

<script>
import { useRandomStore } from '.././stores/RandomStore';

export default {
  name: 'NavigationButtons',
  setup() {
    const randomStore = useRandomStore();
    // const generateRandom = () => {
    //   randomStore.generateRandomNumber();
    // };
    // return { generateRandom };
    const setTimestamp = () => {
      randomStore.setTimestamp(); // Llama a la acción para guardar la fecha y hora
    };
    return { setTimestamp };
  },

  methods: {
    // openEJR() {
    //   this.setTimestamp()
    //     this.$router.push({ name: 'TestBackend' })
    //     //     let route = this.$router.resolve({ path: "/earlyjazzradio" });
    //     //     window.open(route.href);
    //  },
    navigateTo(routeName) {
      this.$router.push({ name: routeName });
    }
  }
};
</script>

<style scoped>
.header {
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  width: 60vh;
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh;
}

button {
  margin: 10px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
}

.button-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
}
</style>