import { createRouter, createWebHistory } from 'vue-router'
import MainMenu from './../views/MainMenu.vue'
import EnglishAccentCourse from './../views/english-accent-course/MainPage.vue'
import TestBackend from './../components/test-backend/SimpleTestBackend.vue'
import TestPage from './../views/english-accent-course/TestPage.vue'


const routes = [
    {
        name: 'MainMenu',
        component: MainMenu,
        path: '/'
    },
    {
        name: 'EnglishAccentCourse',
        component: EnglishAccentCourse,
        path: '/english-accent-course'
    },
    {
        name: 'TestPage',
        component: TestPage,
        path: '/backend'
    },
    {
        name: 'TestBackend',
        component: TestBackend,
        path: '/test-backend'
    }
]


const router = createRouter(
    {
        history: createWebHistory(),
        routes
    }
)

export default router
